.feedback-button {
    display: inline-block;
    position: fixed;
    right: 0;
    bottom: 10%;
    box-shadow: 0 0 10px black;
    padding: 6px 10px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    writing-mode:vertical-rl;
    border-radius: 10px 0 0 10px;
    border-right: none;
    background-color: #f15a24;
}

a {
    color: white;
}