.metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2vh;
}

.credits {
    display: flex;
    font-size: 2.5vh;
    align-items: center;
}

.socials {
    font-weight: bold;
    padding: 10px;
}

.author {
    font-weight: bold;
}

.hidden {
    display: none;
}

.section-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.5vh;
    margin-top: 120px;
}

.quiz-title {
    font-size: 9vh;
    font-weight: bold;
    display: block;
    width: 100%;
}

.sub-title {
    font-size: 3.5vh;
    font-family: minion-pro, "Times New Roman", serif;
}

@media only screen and (max-width: 650px) {

    .quiz-title {
        font-size: 5vh;
    }

    .sub-title {
        font-size: 3.5vh;
    }

    .socials, .credits, .section-name {
        font-size: 2vh;
    }

}