a {
    text-decoration: none;
}

.header {
    align-items: center;
    background-color: white;
    box-shadow: 0 0 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    min-height: 64px;
    position: fixed;
    top: 0;
    width: 100%;
}

.left {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    width: 100%;
    max-width: 1536px;
}

.links {
    font-family: franklin-gothic-urw, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 6px 16px;
    transition: ease 0.4s;
}

.links:hover{
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
    transition: ease 0.4s;
}

.wordmark {
    font-family: minion-pro, "Times New Roman", serif;
    font-weight: normal;
    font-size: 1.5rem;
    color: black;
}

@media only screen and (max-width: 500px) {

    .wordmark {
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .links {
        font-size: 1rem;
    }
}

