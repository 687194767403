.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question-container {
    column-gap: 60px;
    font-weight: 500;
    display: flex;
    align-items: start;
    width: 100%;
    padding: 20px;
    margin: 10px 0;
}

.question {
    display: flex;
    max-width: 50%;
    flex-direction: column;
    font-size: 3.5vh;
    font-weight: bold;
}

.question-img {
    width: 40%;
    height: auto;
    display: inline-block;
    margin-bottom: 0;
    flex-direction: column;
    align-self: flex-start;
    object-fit: cover;
    object-position: 100% 0;
    border-radius: 10px;
}
 
.choices {
    display: flex;
    flex-direction: column;
    font-size: 3vh;
    font-weight: normal;
}

.choices > label {
    line-height: 1.2;
    margin: 10px 0;
}

input[type="radio"] {
    appearance: none;
}

.option {
    transition: all 0.1s ease-in-out;
    background-color: #eaeaea;
    width: fit-content;
    padding: 10px 15px;
    margin: -15px 0;
    border-radius: 20px;
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
    color: gray;
}

.option:hover {
    transform: scale(110%);
    animation-duration: 100ms;
}

@keyframes bounce {
    0% {}
    30% {scale: 90%;}
    70% {scale: 110%;}
    100% {scale: 100%;}
}

:checked + .option {
    animation: 0.3s ease-in 0s bounce;
    font-weight: bold;
    background-color: #f15a24;
    color: black;
}

.green {
    border: #6ad56a solid 10px;
    box-shadow: 0 0 10px #6ad56a;
    border-radius: 30px;
}

.explain {
    margin-top: 20px;
    font-weight: normal;
    font-size: 3vh;
    font-family: minion-pro, "Times New Roman", serif;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 650px) {
    .question-container {
        flex-direction: column;
        row-gap: 20px;
    }

    .question {
        max-width: 100%;
    }

    .question-img {
        width: 90%;
    }

    .green, .red {
        padding: 10px;
    }
}

