.content {
    padding-left: 7vw;
    padding-right: 7vw;
}

.finish-quiz {
    width: 40%;
    background-color: black;
    border-radius: 50px;
    border: none;
    color: white;
    font-family: inherit;
    font-size: 4vh;
    font-weight: bold;
    padding: 20px 30px;
    text-align: center;
    transition: 0.2s ease-in-out;
    margin: 20px 0;
}

.finish-quiz:hover {
    background-color: #f15a24;
    cursor: pointer;
    transform: scale(110%);
    transition: 0.2s ease-in-out;
}

.finish-quiz:active {
    transform: scale(90%);
    transition: 0.1s ease-in-out;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.Modal {
    background-color: ghostwhite;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    border-radius: 20px;
    padding: 0 20px 20px 20px;
    position: absolute;
    top: 30%;
    left: 25%;
    right: 25%;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(75, 22, 2, 0.55);
}

.modal-option {
    margin-right: 10px;
    margin-top: 20px;
    background-color: black;
    border-radius: 30px;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    font-family: inherit;
}

.hidden {
    display: none;
}

.loading {
    text-align: center;
    font-weight: bold;
    font-size: 20pt;
    color: gray;
    margin-top: 200px;
}

@media only screen and (max-width: 650px) {
    .content {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .finish-quiz {
        width: 90%;
        font-size: 6vw;
        text-align: center;
    }

    .finish-quiz:hover {}

    .finish-quiz:active {
        background-color: #f15a24;
        cursor: pointer;
        transition: 0.1s ease-in-out;
    }

    .Modal {
        left: 5%;
        right: 5%;
    }

    .modal-option {
        font-size: 20px;
    }
}